import { compareArrays, validateStatus } from "@/utils";
import Cookies from "js-cookie";

const API_HOST = process.env.VUE_APP_BASE_SCRIPT;
export const baseAPI = process.env.VUE_APP_BASE_API;

export default {
  state: {
    tariffs: [],
    tariffsStatus: "loading",
    regionStatus: "",
    regionConfirm: { siteId: "siteMSK" },
    isGlobalLoading: false,
    isLoading: false,
  },
  mutations: {
    setProp(state, payload) {
      Object.keys(payload).forEach(
        (propName) => (state[propName] = payload[propName])
      );
    },
  },
  actions: {
    async fetchTariffs({ commit }, data) {
      try {
        const response = await fetch(`${API_HOST}/tariffs`, {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
          },
        });
        const { status } = response;

        if (!validateStatus(status)) {
          throw new Error(`server response with status ${status}`);
        }

        commit("setProp", { tariffs: await response.json() });
        commit("setProp", { tariffsStatus: "success" });
      } catch (error) {
        commit("setProp", { tariffsStatus: "error" });
      }
    },
    async fetchApiTariffs({ state }, { region }) {
      try {
        const response = await fetch(
          `${baseAPI}/public/tariffs/?region=${region}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const { status } = response;

        if (!validateStatus(status)) {
          throw new Error(`server response with status ${status}`);
        }

        const { items } = await response.json();

        state.tariffs.push(
          ...items.map((i) => ({
            region: i.region,
            ...i.description,
            ...i.additional_description,
          }))
        );
      } catch (error) {
        console.log(error);
      }
    },
    async fetchRegion({ commit }) {
      try {
        const response = await fetch(`${API_HOST}/region`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        const { status } = response;

        if (!validateStatus(status)) {
          throw new Error(`server response with status ${status}`);
        }
        const region = await response.json();

        commit("setProp", { regionConfirm: region });
        commit("setProp", { regionStatus: "success" });
      } catch (error) {
        commit("setProp", { regionStatus: "error" });
      }
    },
    async fetchNotifications({ commit }) {
      try {
        const response = await fetch(`${baseAPI}/public/notifications/`, {
          method: "GET",
          header: {
            "Content-Type": "application/json",
          },
        });

        const { status } = response;

        if (!validateStatus(status)) {
          throw new Error(`server response with status ${status}`);
        }

        const { items } = await response.json();

        let userNotifications = JSON.parse(Cookies.get("notifications"));

        commit("notifications/setNotifications", items);

        if (!userNotifications || compareArrays(items, userNotifications)) {
          commit("notifications/setHasNots", true);
        }
      } catch (error) {
        console.log(error);
      }
    },

    async fetchPromo({ commit }) {
      try {
        const response = await fetch(`${baseAPI}/public/promo/`, {
          method: "GET",
          header: {
            "Content-Type": "application/json",
          },
        });

        const { status } = response;

        if (!validateStatus(status)) {
          throw new Error(`server response with status ${status}`);
        }

        const promo = await response.json();

        promo.subscriptions.sort((a, b) => (a.price > b.price ? 1 : -1));

        commit("promo/setPromo", promo);
      } catch (error) {
        console.log(error);
      }
    },

    async fetchEsim({ commit }) {
      try {
        const response = await fetch(`${baseAPI}/public/esim/`, {
          method: "GET",
          header: {
            "Content-Type": "application/json",
          },
        });

        const { status } = response;

        if (!validateStatus(status)) {
          throw new Error(`server response with status ${status}`);
        }

        const esim = await response.json();

        commit("esim/setEsim", esim);
      } catch (error) {
        console.log(error);
      }
    },

    async fetchSub({ commit }) {
      try {
        const response = await fetch(`${baseAPI}/public/sub/`, {
          method: "GET",
          header: {
            "Content-Type": "application/json",
          },
        });

        const { status } = response;

        if (!validateStatus(status)) {
          throw new Error(`server response with status ${status}`);
        }

        const { steps } = await response.json();

        commit("sub/setSub", steps);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
