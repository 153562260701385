<template>
  <SectionLayout color="white">
    <template #body>
      <SectionTariffs id="tariffs" />
      <DetailOptions id="home-tariffs" />
    </template>
  </SectionLayout>
</template>

<script setup>
import DetailOptions from "@/components/DetailOptions.vue";
import SectionLayout from "@/components/SectionLayout.vue";
import SectionTariffs from "@/components/SectionTariffs.vue";
import useLanguage from "@/hooks/useLanguage";
import useTranslate from "@/hooks/useTranslate";
import { onMounted, watch } from "vue";
import { useStore } from "vuex";

const { getLanguage } = useLanguage();
const { translate } = useTranslate();
const store = useStore();

onMounted(async () => {
  store.commit("setProp", { isLoading: true });
  if (getLanguage() !== "ru") {
    await translate("#tariffs", document, "home");
    await translate("#home-tariffs", document, "frame-tariffs");
  }
  store.commit("setProp", { isLoading: false });
});

watch(getLanguage, async () => {
  store.commit("setProp", { isLoading: true });
  await translate("#tariffs", document, "home");
  await translate("#home-tariffs", document, "frame-tariffs");
  store.commit("setProp", { isLoading: false });
});
</script>
